<template>
	<ZyroModal
		max-width="868px"
		max-height="700px"
		class="modal-add-page"
		:title="$t('common.addNewPage')"
		:subtitle=" $t('builder.addNewPageModal.description')"
		@close-modal="closeModal"
	>
		<div
			v-if="!isLoading"
			class="page-cards"
		>
			<div
				class="page-cards__item"
				data-qa="builder-addnewpagemodal-card-addblankpage"
				@click="addNewPage()"
			>
				<div class="empty-block">
					<div class="empty-block__dashed-border">
						<ZyroSvg
							class="empty-block__icon"
							name="plus-circle"
						/>
						<div class="empty-block__title z-body-small">
							{{ $t('builder.addNewPageModal.emptyBlockTitle') }}
						</div>
					</div>
				</div>
			</div>
			<div
				v-for="(page, key) in allowedTemplatePages"
				:key="page.path"
				v-qa="`builder-addnewpagemodal-card-${page.name}`"
				class="page-cards__item"
				@click="addNewPage(key)"
			>
				<div class="page-cards__picture">
					<ZyroSvg name="picture" />
				</div>
				<div class="page-cards__item-name z-body-small">
					{{ page.name }}
				</div>
			</div>
		</div>
	</ZyroModal>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import { BLOG_PAGE_TYPE } from '@/constants';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { filterObject } from '@/utils/object';

export default {
	data() {
		return { isLoading: false };
	},
	computed: {
		...mapState(['template']),
		...mapGetters(['doesWebsiteHaveTemplate']),
		allowedTemplatePages: ({ template }) => (
			template?.pages
				? filterObject(
					template.pages,
					({ value }) => value.type !== BLOG_PAGE_TYPE,
				) : {}
		),
	},
	created() {
		this.setupTemplate();
	},
	methods: {
		...mapActions([
			'getTemplate',
			'addBlock',
		]),
		...mapActions('pages', [
			'duplicatePage',
			'addPage',
		]),
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		addNewPage(pageId) {
			if (pageId) {
				this.duplicatePage({
					type: 'default',
					payload: {
						pageId,
						template: this.template,
					},
				});
			} else {
				this.addPage({ type: 'default' });
				this.addBlock({ previousBlockId: null });
			}

			this.closeModal();
		},
		async setupTemplate() {
			if (!this.template && this.doesWebsiteHaveTemplate) {
				this.isLoading = true;
				await this.getTemplate();
				this.isLoading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.page-cards {
	$this: &;

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-gap: 20px;
	min-height: 200px;
	padding-top: 24px;
	padding-bottom: 24px;

	&__picture {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 200px;
		background-color: $primary-lighter;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		transition: background-color 300ms;
	}

	&__item {
		line-height: 2;
		text-align: center;
		cursor: pointer;
		background-color: $light;
		border-radius: 3px;
		box-shadow: $box-shadow;

		&:hover {
			#{$this}__picture {
				background-color: darken($primary-lighter, 10);
			}
		}

		&:active {
			#{$this}__picture {
				background-color: darken($primary-lighter, 20);
			}
		}
	}

	&__item-name {
		padding: 12px 0;
	}
}

.empty-block {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 16px;
	transition: background-color 300ms;

	&:hover {
		background-color: $grey-200;
	}

	&__dashed-border {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border: 1px dashed $grey-600;
	}

	&__icon {
		margin-bottom: 8px;
	}

	&__title {
		color: $grey-800;
	}
}
</style>
